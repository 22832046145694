import { template as template_05eacba52a294fcb8a8f352c7c37d394 } from "@ember/template-compiler";
const FKLabel = template_05eacba52a294fcb8a8f352c7c37d394(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
