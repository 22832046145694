import { template as template_6510604d58c040e5ad5e2c256ff15fdd } from "@ember/template-compiler";
const FKControlMenuContainer = template_6510604d58c040e5ad5e2c256ff15fdd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
