import { template as template_afe6d9fa32a84cfea16393c4eb5f8a9b } from "@ember/template-compiler";
const FKText = template_afe6d9fa32a84cfea16393c4eb5f8a9b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
